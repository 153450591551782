<template>
  <div>
    <b-sidebar
      id="sidebar-comment"
      sidebar-class="sidebar-lg"
      :visible="newBannerSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateNewBannerSidebar({ visible: val })"
      @hidden="clear"
      @shown="onShow"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>{{ isEdit? 'Editar Banner': 'Novo Banner' }}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-row
          v-if="loading"
          class="justify-content-md-center mt-2"
        >
          <b-spinner big />
        </b-row>
        <b-form
          v-if="!loading"
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Título"
                label-for="new-banner-title"
              >
                <b-form-input
                  id="new-banner-title"
                  v-model="title"
                  :class="{ 'is-invalid': v$.title.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.$error">
                    Você deve informar um título
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <file-chooser
                id="new-banner-large-image"
                label="Versão desktop"
                v-model="largeImage"
                :url="largeImageUrl"
                :disabled="saving"
                :thereIsError="v$.largeImage.$error && v$.largeImage.$invalid"
                errorMessage="Arquivo deve ter menos de 2MB"
                only-images
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <file-chooser
                id="new-banner-small-image"
                label="Versão mobile"
                v-model="smallImage"
                :url="smallImageUrl"
                :disabled="saving"
                :thereIsError="v$.smallImage.$error && v$.smallImage.$invalid"
                errorMessage="Arquivo deve ter menos de 2MB"
                only-images
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <b-form-group
                label="Link clicável"
                label-for="new-banner-link"
              >
                <b-form-input
                  id="new-banner-link"
                  v-model="linkUrl"
                  type="url"
                />
              </b-form-group>
            </b-col>
          </b-row>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? 'Salvando...' : 'Salvar' }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BAvatar, BButton, BFormInvalidFeedback, BFormTextarea
} from 'bootstrap-vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import FileChooser from '@/modules/shared/components/FileChooser'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    vSelect,
    FileChooser
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      saving: false,
      title: undefined,
      largeImage: undefined,
      largeImageUrl: undefined,
      smallImage: undefined,
      smallImageUrl: undefined,
      linkUrl: undefined
    }
  },
  computed: {
    ...mapGetters({
      newBannerSidebar: types.NEW_BANNER_SIDEBAR
    }),
    isEdit() {
      if(this.newBannerSidebar.id){
        return true
      }
      return false
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations(){
    return {
      title: { required },
      largeImage: { required: requiredIf(() => !this.isEdit)},
      smallImage: { required: requiredIf(() => !this.isEdit)},
      linkUrl: {}
    }
  },
  methods: {
    ...mapMutations({
      mutateNewBannerSidebar: types.MUTATE_NEW_BANNER_SIDEBAR
    }),
    ...mapActions({
      storeDashboardBanner: types.STORE_DASHBOARD_BANNER,
      getDashBanner: types.GET_DASHBOARD_BANNER
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      this.saving = true

      const content = {
        title: this.title,
        large_image: this.largeImage,
        small_image: this.smallImage,
        link_url: this.linkUrl
      }
      const request = new FormData();
      for (let [key, value] of Object.entries(content)) {
        if (value && value !== undefined && value !== "" && value !== "null") {
          request.append(key, value);
        }
      }
      const payload = {
        id: this.newBannerSidebar.id,
        request
      }
      this.storeDashboardBanner(payload)
        .then(response => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "O banner foi salvo com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.mutateNewBannerSidebar({visible: false})
            this.newBannerSidebar.saveAction()
          })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar o banner. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    onShow(){
      if(this.isEdit){
        this.loading = true
        this.getDashBanner(this.newBannerSidebar.id)
          .then(response => {
            if(response.data.id){
              this.title = response.data.title
              this.largeImageUrl = response.data.large_image_url
              this.smallImageUrl = response.data.small_image_url
              this.linkUrl = response.data.link_url
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    clear(){
      this.newBannerSidebar.id = undefined
      this.title = undefined;
      this.largeImage = undefined;
      this.largeImageUrl = undefined;
      this.smallImage = undefined;
      this.smallImageUrl = undefined;
      this.linkUrl = undefined;
      this.v$.$reset();
    },
  }
}
</script>
